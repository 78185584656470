<template>
    <Modal @reset_modal="$emit('reset_modal')">
        <div class="ytm-default-text" style="font-size: 1.75rem; font-weight: 600; text-align: center; margin-bottom: 1.75rem">
            Создать новое занятие
        </div>
        <div v-if="tariffId === -1">
            <p class="ytm-default-text" style="text-align: center; font-size: 1.25rem">
                Чтобы создать занятие, нужно выбрать тариф
            </p>
            <div style="margin-top: 2rem; width: 100%; display: flex; justify-content: center">
                <div
                    class="ytm-blue-button ytm-default-text"
                    style="font-size: 0.9rem; color: #FFFFFF; font-weight: 600"
                    @click="$router.push('/account?section=tariff')"
                >
                    К выбору тарифа
                </div>
            </div>
        </div>
        <div v-else-if="loading">
            <p class="ytm-default-text" style="text-align: center; font-size: 1.25rem">
                Загрузка...
            </p>
            <div style="margin-top: 2rem; width: 100%; display: flex; justify-content: center">
                <div
                    class="ytm-blue-button ytm-default-text"
                    style="font-size: 0.9rem; color: #FFFFFF; font-weight: 600"
                    @click="$emit('reset_modal')"
                >
                    Отмена
                </div>
            </div>
        </div>
        <div v-else-if="failed">
            <p class="ytm-default-text" style="text-align: center; font-size: 1.25rem; color: red">
                Упс, произошла ошибка загрузки :(
            </p>
            <div style="margin-top: 2rem; width: 100%; display: flex; justify-content: center">
                <div
                    class="ytm-blue-button ytm-default-text"
                    style="font-size: 0.9rem; color: #FFFFFF; font-weight: 600"
                    @click="$emit('reset_modal')"
                >
                    Отмена
                </div>
            </div>
        </div>
        <div v-else>
            <CourseForm v-if="!courseSpecified" v-model:courseLink="course" :courses="coursesAsTeacher" @courseSpecified="courseSpecified = true"/>
            <LessonForm v-if="courseSpecified" v-model:duration="duration" :formData="formData" :courseLink="course" @lesson_created="onLessonCreated"/>
        </div>
    </Modal>
</template>

<script>
import 'v-calendar/dist/style.css';
import Modal from "@/components/redesign/Modal.vue";
import InputGroup from "@/components/redesign/Form/InputGroup.vue";
import SelectGroup from "@/components/redesign/Form/SelectGroup.vue";
import DatetimeGroup from "@/components/redesign/Form/DatetimeGroup.vue";
import DefaultTip from "@/components/redesign/Tips/DefaultTip.vue";
import CourseForm from "@/components/LessonsPage/NewLessonModal/CourseForm.vue";
import LessonForm from "@/components/LessonsPage/NewLessonModal/LessonForm.vue";

export default {
    name: 'NewLessonModal',
    components: {LessonForm, CourseForm, DefaultTip, SelectGroup, Modal, InputGroup, DatetimeGroup},
    data() {
        return {
            formData: {
                topic: '',
                dtStart: new Date(),
                dtFinish: null,
            },
            course: '',
            courseSpecified: false,
            duration: 80,
            is_dd_shown: false,
            creating: false,
            errorMsg: '',
        };
    },
    computed: {
        tariffId() {
            const userInfo = this.$store.getters['user_info/user_info'];
            if (userInfo && userInfo.tariffState && userInfo.tariffState.tariff) {
                return userInfo.tariffState.tariff.id;
            } else {
                return -1;
            }
        },
        loading() {
            return this.$store.getters['courses/loading'];
        },
        failed() {
            return this.$store.getters['courses/failed'];
        },
        coursesAsTeacher() {
            const courses = this.$store.getters['courses/courses'];
            return courses ? courses.asTeacher : [];
        },
    },
    methods: {
        onDurationInput() {
            this.duration = Math.max(0, Math.round(this.duration));
            this.duration = Math.min(60 * 12, Math.round(this.duration));
        },
        onLessonCreated() {
            this.$emit('lesson_created');
            this.$emit('reset_modal');
        },
    },
    mounted() {
        this.$store.dispatch('courses/fetchCourses').catch(err => {
            console.error(err);
            this.errorMsg = 'Не удалось загрузить курсы :(';
        });
    },
};
</script>

<style scoped>

</style>