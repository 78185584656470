<template>
  <div>
      <DefaultTip style="margin-bottom: 1.25rem">
          <p class="ytm-default-text" style="font-weight: 600; line-height: 130%; color: #FFFFFF; font-size: 0.9rem; margin-bottom: 0.45rem; max-width: 700px">
              Занятие прикрепляется к&nbspопределённому курсу. Курс&nbsp– это единое пространство, в&nbspкотором вы будете работать с&nbspучеником или&nbspгруппой.
          </p>
          <a class="ytm-default-text" style="font-weight: 700; color: #FFFFFF; font-size: 0.9rem" href="/tutorial" target="_blank" rel="noopener noreferer">
              Узнать больше
              <svg style="position: relative; top: 0.2rem; width: 0.9rem; height: 1rem" viewBox="0 0 34 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2 13.3679C0.895431 13.3679 8.19842e-08 14.2634 0 15.3679C-8.19842e-08 16.4725 0.89543 17.3679 2 17.3679L2 13.3679ZM33.1296 16.7821C33.9106 16.0011 33.9106 14.7348 33.1296 13.9537L20.4016 1.22579C19.6206 0.444737 18.3543 0.444737 17.5732 1.22579C16.7922 2.00683 16.7922 3.27316 17.5732 4.05421L28.8869 15.3679L17.5732 26.6816C16.7922 27.4627 16.7922 28.729 17.5732 29.5101C18.3543 30.2911 19.6206 30.2911 20.4016 29.5101L33.1296 16.7821ZM2 17.3679L31.7153 17.3679L31.7153 13.3679L2 13.3679L2 17.3679Z" fill="white"/>
              </svg>
          </a>
      </DefaultTip>
      <SelectGroup
              title="Курс"
              :options="options"
              v-model="courseLink"
              defaultValue="Не выбран"
              :fillWidth="true"
              @update:modelValue="$emit('update:courseLink', $event)"
      />
      <ExpandCollapseTransition>
          <div v-if="courseLink === '_'">
              <InputGroup
                  class="ytm-modal-input"
                  v-model="formData.title"
                  title="Название"
                  placeholder="Математика Настя 11 класс"
                  :focus="true"
                  @keyup.enter="createCourse"
                  style="margin-top: 1.25rem"
              />
              <InputGroup
                  class="ytm-modal-input"
                  v-model="formData.costPerHour"
                  type="number"
                  title="Стоимость часа"
                  placeholder="Стоимость часа занятий"
                  @input="onCostInput"
                  @keyup.enter="createCourse"
              />
              <SelectGroup
                  title="Тип курса"
                  :options="courseTypeOptions"
                  v-model="isIndividual"
              />
          </div>
      </ExpandCollapseTransition>
      <p v-if="errorMsg" class="ytm-default-text" style="margin-top: 1rem; margin-bottom: -1rem; text-align: center; color: red">
          {{errorMsg}}
      </p>
      <div style="margin-top: 2rem; width: 100%; display: flex; justify-content: center">
          <div
              :class="{'ytm-blue-button ytm-default-text': true, 'ytm-disabled-button': !buttonConfig.enabled}"
              style="font-size: 0.9rem; color: #FFFFFF; font-weight: 600"
              @click="buttonConfig.action"
          >
              {{buttonConfig.title}}
          </div>
      </div>
  </div>
</template>

<script>
import "@/styles/style.css";
import SelectGroup from "@/components/redesign/Form/SelectGroup.vue";
import ExpandCollapseTransition from "@/components/redesign/Transitions/ExpandCollapseTransition.vue";
import InputGroup from "@/components/redesign/Form/InputGroup.vue";
import DefaultTip from "@/components/redesign/Tips/DefaultTip.vue";
import axios from "axios";
import {SERVICE_MAIN_URI} from "@/util/api-host";
import {authHeader} from "@/util/auth-header";

export default {
    name: 'CourseForm',
    components: {DefaultTip, InputGroup, ExpandCollapseTransition, SelectGroup},
    props: {
        courseLink: {
            type: String,
            required: true,
        },
        courses: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            formData: {
                title: '',
                costPerHour: 0,
            },
            isIndividual: '1',
            creating: false,
            errorMsg: '',
        };
    },
    computed: {
        options() {
            const options = [{
                value: '_',
                desc: 'Создать новый',
            }];
            for (const course of this.courses) {
                let about = !course.isIndividual ? 'групповой курс' : course.student ? course.student.name : 'ученик не добавлен';
                options.push({
                    value: course.shortLink,
                    desc: course.title + ' (' + about + ')',
                });
            }
            return options;
        },
        tariffId() {
            const userInfo = this.$store.getters['user_info/user_info'];
            if (userInfo && userInfo.tariffState && userInfo.tariffState.tariff) {
                return userInfo.tariffState.tariff.id;
            } else {
                return -1;
            }
        },
        courseTypeOptions() {
            const options = [{
                value: '1',
                desc: 'Индивидуальный',
            }];
            if (this.tariffId === 1 || Math.floor(this.tariffId / 100) === 3) {
                options.push({
                    value: '0',
                    desc: 'Групповой',
                });
            }
            return options;
        },
        buttonConfig() {
            if (this.creating) {
                return {
                    enabled: true,
                    title: 'Создаём...',
                    action: () => {},
                };
            }
            return {
                enabled: !!this.courseLink,
                title: this.courseLink === '_' ? 'Создать курс' : 'Далее',
                action: this.courseLink === '_' ? this.createCourse : this.goNext,
            };
        },
    },
    methods: {
        onCostInput() {
            this.formData.costPerHour = Math.max(0, Math.round(this.formData.costPerHour));
        },
        createCourse() {
            if (this.creating) {
                return;
            }
            this.formData.title = this.formData.title.trim();
            if (this.formData.title === '') {
                this.errorMsg = 'Заполните, пожалуйста, название';
                return;
            }
            this.errorMsg = '';
            this.creating = true;
            let payload = {
                title: this.formData.title,
                costPerHour: Math.max(0, Math.round(this.formData.costPerHour)),
                isIndividual: this.isIndividual !== '0' ? 1 : 0,
            };
            this.$store.dispatch('auth/autoLogin').then(() => {
                axios.post(
                    SERVICE_MAIN_URI + '/course',
                    payload,
                    {headers: authHeader()},
                ).then(resp => {
                    const courseLink = resp.data.out.courseLink;
                    this.$emit('update:courseLink', courseLink);
                    this.creating = false;
                    this.goNext();
                }).catch(err => {
                    if (err.response && err.response.data.message && err.response.data.message.startsWith('tariffError:')) {
                        this.errorMsg = err.response.data.message.slice('tariffError: '.length);
                    } else {
                        this.errorMsg = 'Упс, не удалось создать курс';
                        console.error(err);
                    }
                    this.creating = false;
                });
            });
        },
        goNext() {
            this.$emit('courseSpecified');
        },
    },
};
</script>

<style scoped>
.ytm-modal-input {
    margin-bottom: 1.25rem;
}
</style>