<template>
    <div class="ytm-container">
        <NewLessonModal v-if="showNewLessonModal" @reset_modal="showNewLessonModal = false" @lesson_created="onLessonCreated"/>
        <Navbar/>
        <p class="ytm-default-text ytm-head-text" style="margin-top: 2.5rem; margin-bottom: 1.5rem">
            Расписание
        </p>
        <div v-if="fromDT" style="display: flex; margin-bottom: 1.5rem">
            <div style="flex-grow: 1">
                <svg
                    viewBox="0 0 15 26" fill="none" xmlns="http://www.w3.org/2000/svg"
                    style="position: relative; top: 0.125rem; height: 1rem; cursor: pointer"
                    @click="prevWeek()"
                >
                    <path d="M13 2L2 13L13 24" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <span class="ytm-default-text" style="margin: 0 0.7rem; font-weight: 600; user-select: none">
                    {{getStrInterval(fromDT, toDT)}}
                </span>
                <svg
                    viewBox="0 0 15 26" fill="none" xmlns="http://www.w3.org/2000/svg"
                    style="position: relative; top: 0.125rem; height: 1rem; cursor: pointer"
                    @click="nextWeek()"
                >
                    <path d="M2 2L13 13L2 24" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            <div v-if="isTeacher">
                <div class="ytm-black-button" style="display: table" @click="showNewLessonModal = true">
                    <svg viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg" style="height: 1.2rem; display: table-cell; vertical-align: middle; margin-right: 0.8rem">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M20.5 3C20.5 1.61929 19.3807 0.5 18 0.5C16.6193 0.5 15.5 1.61929 15.5 3V14.5H3C1.61929 14.5 0.5 15.6193 0.5 17C0.5 18.3807 1.61929 19.5 3 19.5H15.5V32C15.5 33.3807 16.6193 34.5 18 34.5C19.3807 34.5 20.5 33.3807 20.5 32V19.5H32C33.3807 19.5 34.5 18.3807 34.5 17C34.5 15.6193 33.3807 14.5 32 14.5H20.5V3Z" fill="white"/>
                    </svg>
                    <span class="ytm-default-text ytm-create-lesson" style="font-weight: 600; color: #FFFFFF; display: table-cell; vertical-align: middle">
                        Создать занятие
                    </span>
                </div>
            </div>
        </div>
        <Calendar
            v-if="fromDT && toDT"
            :fromDT="fromDT" :toDT="toDT" :events="lessonsForCalendar"
            :showTeacherOnboarding="!loadingUser && !loadingLessons && !isTeacher && lessonsForCalendar.length === 0"
            style="margin-bottom: 4rem"
        />
    </div>
</template>

<script>
import "@/styles/style.css";
import Navbar from "@/components/redesign/Navbar.vue";
import Calendar from "@/components/LessonsPage/Calendar.vue";
import {getStrDate} from "@/util/datetime-to-str";
import axios from "axios";
import {SERVICE_MAIN_URI} from "@/util/api-host";
import {authHeader} from "@/util/auth-header";
import NewLessonModal from "@/components/LessonsPage/NewLessonModal/NewLessonModal.vue";

export default {
    name: 'LessonsPage',
    components: {NewLessonModal, Calendar, Navbar},
    data() {
        return {
            fromDT: null,
            toDT: null,
            showNewLessonModal: false,
            loadingUser: true,
            loadingLessons: true,
            failed: false,
            lessons: [],
        };
    },
    computed: {
        isTeacher() {
            const info = this.$store.getters['user_info/user_info'];
            return info && info.teacherState && info.teacherState.isT;
        },
        lessonsForCalendar() {
            const lessons = [];
            for (const lesson of this.lessons) {
                lessons.push({
                    id: `${lesson.courseLink}:${lesson.lessonId}`,
                    title: lesson.courseTitle,
                    subtitle: lesson.topic,
                    time: new Date(lesson.dtStart),
                    durationMinutes: Math.abs(lesson.dtFinish - lesson.dtStart) / 1000 / 60,
                    path: `/course/${lesson.courseLink}/lesson/${lesson.lessonId}`,
                });
            }
            return lessons;
        },
    },
    methods: {
        getWeekByDate(curr) {
            const monday = curr.getDate() - (curr.getDay() + 6) % 7;
            const fromDT = new Date(new Date(curr).setDate(monday));
            fromDT.setHours(0, 0, 0, 0);
            const sunday = monday + 6;
            const toDT = new Date(new Date(curr).setDate(sunday));
            toDT.setHours(23, 59, 59, 999);
            return {fromDT, toDT};
        },
        getStrInterval(fromDT, toDT) {
            if (fromDT.getFullYear() !== toDT.getFullYear()) {
                return 'С ' + getStrDate(fromDT, true, true) + ' по ' + getStrDate(toDT, true, true);
            } else if (fromDT.getMonth() !== toDT.getMonth()) {
                return 'С ' + getStrDate(fromDT, true, false) + ' по ' + getStrDate(toDT, true, true);
            } else {
                return 'С ' + getStrDate(fromDT, false, false) + ' по ' + getStrDate(toDT, true, true);
            }
        },
        prevWeek() {
            this.fromDT = new Date(this.fromDT.setDate(this.fromDT.getDate() - 7));
            this.toDT = new Date(this.toDT.setDate(this.toDT.getDate() - 7));
            this.getLessons();
        },
        nextWeek() {
            this.fromDT = new Date(this.fromDT.setDate(this.fromDT.getDate() + 7));
            this.toDT = new Date(this.toDT.setDate(this.toDT.getDate() + 7));
            this.getLessons();
        },
        getLessons() {
            this.loadingLessons = true;
            this.lessons = [];
            this.$store.dispatch('auth/autoLogin').then(() => {
                axios.post(
                    SERVICE_MAIN_URI + '/getLessonsForUser',
                    {
                        fromDT: this.fromDT,
                        toDT: this.toDT,
                    },
                    {headers: authHeader()},
                ).then(resp => {
                    const lessonsList = resp.data.out;
                    const lessons = [];
                    for (const lesson of lessonsList) {
                        lesson.dtStart = new Date(lesson.dtStart);
                        lesson.dtFinish = new Date(lesson.dtFinish);
                        lessons.push(lesson);
                    }
                    lessons.sort((lhs, rhs) => lhs.dtStart < rhs.dtStart ? -1 : rhs.dtStart > lhs.dtStart ? 1 : 0);
                    this.lessons = lessons;
                    this.loadingLessons = false;
                }).catch(err => {
                    this.failed = true;
                    this.loadingLessons = false;
                    console.error(err);
                });
            });
        },
        onLessonCreated() {
            this.getLessons();
        },
    },
    mounted() {
        const {fromDT, toDT} = this.getWeekByDate(new Date());
        this.fromDT = fromDT;
        this.toDT = toDT;
        this.$store.dispatch('user_info/fetchUserInfo').then(() => {
            this.loadingUser = false;
        }).catch(err => {
            this.failed = true;
            this.loadingUser = false;
        });
        this.getLessons();
    },
};
</script>

<style scoped>
.ytm-create-lesson {
    white-space: nowrap;
}
</style>