<template>
    <div class="ytm-calendar-wrapper">
        <div class="ytm-week">
            <div class="ytm-days">
                <div/>
                <div/>
                <div v-for="(day, idx) in days" :key="idx" v-html="day" class="ytm-day ytm-default-text"/>
            </div>
            <div class="ytm-week-content" ref="content">
                <div v-for="(time, idx) in times" :key="idx" v-html="time" class="ytm-time ytm-default-text" :style="'grid-row: ${idx + 1}'"/>
                <div class="ytm-filler-col"/>
                <div v-for="idx in 7" :key="idx" class="ytm-col" :style="getStyleForColumn(idx)"/>
                <div v-for="idx in 24" :key="idx" class="ytm-row" :style="getStyleForRow(idx)"/>
                <div
                    v-for="evt in events" :key="evt.id"
                    @click="$router.push(evt.path)"
                    :class="{'ytm-event': true, 'ytm-event-past': isPastEvent(evt)}" :style="getStyleForEvent(evt)"
                >
                    <p class="ytm-default-text ytm-event-title">
                        {{evt.title}}
                    </p>
                    <p v-if="evt.subtitle" class="ytm-default-text ytm-event-subtitle">
                        {{evt.subtitle}}
                    </p>
                </div>
                <div class="ytm-current-time" :style="currTimeStyle" ref="currTimeElement">
                    <div class="ytm-circle"/>
                </div>
            </div>
            <div v-if="showTeacherOnboarding" style="position: absolute; top: 2.5rem; height: 100%; width: 100%; backdrop-filter: blur(1px); display: flex; flex-direction: column; justify-content: space-around; align-items: center; z-index: 1">
                <div style="flex-grow: 1"/>
                <div style="padding: 2rem 3rem; background-color: #FFFFFF; box-shadow: 6px 6px 24px rgba(0, 0, 0, 0.2); border-radius: 2.5rem; text-align: center">
                    <p class="ytm-default-text" style="font-size: 1.25rem; font-weight: 600; user-select: none">
                        За этот период занятий нет
                    </p>
                    <div style="margin: 2rem 0 1.25rem 0; width: 100%; display: flex; justify-content: center">
                        <a
                            class="ytm-blue-button ytm-default-text"
                            style="color: #FFFFFF; font-size: 1rem; font-weight: 600; user-select: none; white-space: nowrap"
                            href="/account"
                            target="_blank"
                        >
                            Стать преподавателем
                        </a>
                    </div>
                    <p class="ytm-default-text" style="user-select: none">
                        чтобы создавать занятия
                    </p>
                </div>
                <div style="flex-grow: 2"/>
            </div>
        </div>
    </div>
</template>

<script>
import "@/styles/style.css";
import {getDDMMYYYY} from "@/util/datetime-to-str";
import {onBeforeUnmount, ref} from "vue";

export default {
    name: 'Calendar',
    props: {
        fromDT: {
            type: Date,
            required: true,
        },
        toDT: {
            type: Date,
            required: true,
        },
        events: {
            type: Array,
            required: true,
        },
        showTeacherOnboarding: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const currTime = ref(new Date());
        const movingCurrTime = setInterval(() => {
            currTime.value = new Date();
        }, 60 * 1000);
        onBeforeUnmount(() => {
            clearInterval(movingCurrTime);
        });
        return {currTime};
    },
    data() {
        return {
            times: ['01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'],
        };
    },
    computed: {
        days() {
            const from = new Date(this.fromDT);
            from.setHours(0, 0, 0, 0);
            const to = new Date(this.toDT);
            to.setHours(0, 0, 0, 0);
            const days = [];
            for (let dt = from; dt <= to; dt.setDate(dt.getDate() + 1)) {
                if (dt.getFullYear() === this.currTime.getFullYear() && dt.getMonth() === this.currTime.getMonth() && dt.getDate() === this.currTime.getDate()) {
                    days.push(`<p style="background-color: var(--ytm-red); color: #FFFFFF; font-weight: 600; padding: 0.2rem 0.3rem; border-radius: 0.6rem">${getDDMMYYYY(dt, true)}</p>`);
                } else {
                    days.push(`<p style="padding: 0.2rem 0.3rem">${getDDMMYYYY(dt, true)}</p>`);
                }
            }
            return days;
        },
        currTimeStyle() {
            if (!this.currTime || this.currTime < this.fromDT || this.currTime > this.toDT) {
                return `display: none`;
            }
            const column = (this.currTime.getDay() + 6) % 7 + 3;
            const row = this.currTime.getHours() + 1;
            const mt = this.currTime.getMinutes() / 60 * 4;
            return `grid-column: ${column}; grid-row: ${row}; margin-top: ${mt}rem; pointer-events: none`;
        },
        eventsMargins() {
            const result = {};
            const intersect = (evt, other) => {
                const maxStart = Math.max(evt.time.getTime(), other.time.getTime());
                const minFinish = Math.min(
                    evt.time.getTime() + evt.durationMinutes * 60 * 1000,
                    other.time.getTime() + other.durationMinutes * 60 * 1000,
                );
                return maxStart < minFinish;
            };
            for (let i = 0; i < this.events.length; i++) {
                const mls = new Set();
                for (let j = 0; j < i; j++) {
                    if (intersect(this.events[i], this.events[j])) {
                        mls.add(result[this.events[j].id].ml);
                    }
                }
                let ml = 0;
                for (ml = 0; mls.has(ml); ml++) { }
                result[this.events[i].id] = { ml };
            }
            return result;
        },
    },
    methods: {
        getStyleForColumn(idx) {
            return `grid-column: ${idx + 2}`;
        },
        getStyleForRow(idx) {
            return `grid-row: ${idx}`;
        },
        getStyleForEvent(evt) {
            const column = (evt.time.getDay() + 6) % 7 + 3;
            const start = new Date(evt.time);
            const finish = new Date(evt.time.getTime() + evt.durationMinutes * 60 * 1000);
            const rowToStart = start.getHours() + 1;
            const rowToFinish = finish.getHours() + 1;
            const mt = start.getMinutes() / 60 * 4;
            const mb = (1 - finish.getMinutes() / 60) * 4;
            const margins = this.eventsMargins;
            return `grid-column: ${column}; grid-row: ${rowToStart} / span ${rowToFinish - rowToStart + 1}; margin: calc(${mt}rem - 1px) 0.3rem ${mb}rem calc(1px + ${margins[evt.id].ml} * 0.75rem)`;
        },
        isPastEvent(evt) {
            return new Date(evt.time.getTime() + evt.durationMinutes * 60 * 1000) < this.currTime;
        },
        scrollToCurrTimeIfNeeded() {
            if (this.currTime && this.currTime >= this.fromDT && this.currTime <= this.toDT) {
                const target = Math.max(0, this.$refs.currTimeElement.offsetTop - this.$refs.content.clientHeight / 3);
                if (target !== this.$refs.content.scrollTop) {
                    this.$refs.content.scrollTo({
                        top: target,
                        behavior: 'smooth',
                    });
                }
            }
        },
    },
    mounted() {
        this.scrollToCurrTimeIfNeeded();
    },
    updated() {
        this.scrollToCurrTimeIfNeeded();
    },
};
</script>

<style scoped>
.ytm-calendar-wrapper {
    position: relative;
    width: 100%;
    height: calc(min(75vh, 24 * 4rem) + 3rem);
}
.ytm-week {
    width: 100%;
    display: grid;
    grid-template-rows: 3rem auto;
    position: absolute;
}
.ytm-days {
    display: grid;
    place-content: center;
    grid-template-columns: 2.5rem 10px repeat(7, 1fr);
    position: sticky;
    background-color: #FFFFFF;
    top: 0;
    z-index: 10;
    border-bottom: 2px solid #DADCE0;
}
.ytm-day {
    display: flex;
    justify-content: center;
    user-select: none;
}
.ytm-week-content {
    position: relative;
    display: grid;
    grid-template-columns: 2.5rem 10px repeat(7, 1fr);
    grid-template-rows: repeat(24, 4rem);
    max-height: 75vh;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.ytm-week-content::-webkit-scrollbar {
    display: none;
}
.ytm-time {
    grid-column: 1;
    text-align: right;
    align-self: end;
    font-size: 80%;
    position: relative;
    bottom: -1rex;
    color: #70757a;
    padding-right: 0.1rem;
    user-select: none;
}
.ytm-col {
    border-left: 1px solid #DADCE0;
    grid-row: 1 / span 24;
    grid-column: span 1;
}
.ytm-filler-col {
    grid-row: 1 / -1;
    grid-column: 2;
}
.ytm-row {
    grid-column: 2 / -1;
    border-bottom: 1px solid #DADCE0;
}
.ytm-event {
    border: 1px solid #FFFFFF;
    border-radius: 0.5rem;
    padding: 0.5rem;
    margin-right: 0.5rem;
    cursor: pointer;
    background-color: var(--ytm-blue);
    overflow: hidden;
    transition: transform .2s ease-in-out;
}
.ytm-event:hover {
    transform: scale(1.02);
}
.ytm-event-past {
    background-color: var(--ytm-blue-lighten);
}
.ytm-event-title {
    font-size: 0.8rem;
    font-weight: 700;
    color: #FFFFFF;
    margin-bottom: 0.3rem;
    user-select: none;
}
.ytm-event-subtitle {
    font-size: 0.7rem;
    font-weight: 600;
    color: #FFFFFF;
    user-select: none;
}
.ytm-current-time {
    border-top: 2px solid var(--ytm-red);
    position: relative;
}
.ytm-circle {
    width: 12px;
    height: 12px;
    border: 1px solid var(--ytm-red);
    border-radius: 50%;
    background: var(--ytm-red);
    position: relative;
    top: -7px;
    left: -6px;
}
</style>