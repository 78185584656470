<template>
    <div>
        <InputGroup
            class="ytm-modal-input"
            v-model="formData.topic"
            title="Тема"
            placeholder="Экономические задачи"
            :focus="true"
            @keyup.enter="createLesson"
        />
        <DatetimeGroup
            class="ytm-modal-input"
            v-model="formData.dtStart"
            title="Планируемые дата и время начала"
        />
        <InputGroup
            class="ytm-modal-input"
            v-model="duration"
            type="number"
            title="Продолжительность (в минутах)"
            placeholder="Продолжительность занятия в минутах"
            @input="onDurationInput"
            @keyup.enter="createLesson"
        />
        <p v-if="errorMsg" class="ytm-default-text" style="margin-top: 1rem; margin-bottom: -1rem; text-align: center; color: red">
            {{errorMsg}}
        </p>
        <div style="margin-top: 2rem; width: 100%; display: flex; justify-content: center">
            <div
                :class="{'ytm-blue-button ytm-default-text': true, 'ytm-disabled-button': !buttonConfig.enabled}"
                style="font-size: 0.9rem; color: #FFFFFF; font-weight: 600"
                @click="createLesson"
            >
                {{buttonConfig.title}}
            </div>
        </div>
    </div>
</template>

<script>
import DatetimeGroup from "@/components/redesign/Form/DatetimeGroup.vue";
import InputGroup from "@/components/redesign/Form/InputGroup.vue";
import axios from "axios";
import {SERVICE_MAIN_URI} from "@/util/api-host";
import {authHeader} from "@/util/auth-header";

export default {
    name: 'LessonForm',
    components: {InputGroup, DatetimeGroup},
    props: {
        formData: {
            type: Object,
            required: true,
        },
        duration: {
            type: Number,
            required: true,
        },
        courseLink: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            creating: false,
            errorMsg: '',
        };
    },
    computed: {
        buttonConfig() {
            if (this.creating) {
                return {
                    enabled: true,
                    title: 'Создаём...',
                };
            }
            return {
                enabled: true,
                title: 'Создать занятие',
            };
        },
    },
    methods: {
        onDurationInput() {
            const duration = Math.min(60 * 12, Math.max(0, Math.round(this.duration)));
            this.$emit('update:duration', duration);
        },
        createLesson() {
            if (this.creating) {
                return;
            }
            this.formData.topic = this.formData.topic.trim();
            if (!this.formData.topic) {
                this.errorMsg = 'Укажите, пожалуйста, тему занятия';
                return;
            }
            if (!this.courseLink) {
                this.errorMsg = 'Не выбран курс';
                return;
            }
            this.errorMsg = '';
            this.creating = true;
            let payload = this.formData;
            payload.dtFinish = new Date(payload.dtStart);
            payload.dtFinish.setTime(payload.dtFinish.getTime() + this.duration * 60 * 1000);
            this.$store.dispatch('auth/autoLogin').then(() => {
                axios.post(
                    SERVICE_MAIN_URI + '/createLesson',
                    payload,
                    {
                        headers: authHeader(),
                        params: {shortLink: this.courseLink},
                    },
                ).then(resp => {
                    this.$emit('lesson_created');
                    this.creating = false;
                    this.$emit('reset_modal');
                }).catch(err => {
                    this.creating = false;
                    this.errorMsg = 'Упс, не удалось создать занятие. Уже чиним!';
                });
            });
        },
    },
};
</script>

<style scoped>
.ytm-modal-input {
    margin-bottom: 1.25rem;
}
</style>