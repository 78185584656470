<template>
    <div class="ytm-input-group">
        <p class="ytm-default-text ytm-input-group-title">
            {{title}}
        </p>
        <YTMSelect
                v-model="modelValue"
                :options="options"
                :defaultValue="defaultValue"
                :fillWidth="fillWidth"
                @update:modelValue="onChange"
        />
    </div>
</template>

<script>
import "./forms.css";
import YTMSelect from "@/components/redesign/Form/YTMSelect";

export default {
    name: 'SelectGroup',
    components: {YTMSelect},
    props: {
        title: {
            type: String,
            required: true,
        },
        modelValue: {},
        options: {
            type: Array,
            required: true,
        },
        defaultValue: {
            type: String,
            default: ' ',
        },
        fillWidth: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        onChange(newValue) {
            this.$emit('update:modelValue', newValue);
        },
    },
};
</script>

<style scoped>

</style>